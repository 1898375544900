<template>
    <div
        class="gst-search-hotel-modal gst-search-hotel-expanded-modal u-height-100 d-flex flex-column">
        <HeaderVariant1
            class="flex-grow-0"
            :title="$t( '_common:terms.hotelSearch' )"
            :show-close-icon="false" />
        <div class="u-width-100 flex-grow-1 u-overflow-auto-y">
            <LocationSearchStoreDropDown
                ref="location"
                :value="location"
                class="ma-4"
                :error-messages="_extractValidationsMessages( 'location' )"
                @input="onLocationChangeDo" />
            <template v-if="!isFormWizard">
                <DatePickerTooltipDisabledDates
                    v-model="dates"
                    full-width
                    flat
                    range
                    color="primary"
                    :min="minBookingStartDate"
                    class="gst-search-hotel-modal__picker"
                    no-title />
                <div
                    class="d-flex flex-column pa-4 flex-grow-1"
                    :class="[$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'pr-3']">
                    <h5 class="gst-rooms-tabs-filters__guests-title">
                        {{ $t( '_common:terms.guest_plural') }}
                    </h5>
                    <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                        <p>{{ $t( '_common:terms.adult_plural' ) }}</p>
                        <div>
                            <NumericStep :min="1" :max="99" :value="adultsCount" :icons-attrs="iconsAttrs" @input="updateAdultsCount" />
                        </div>
                    </div>
                    <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                        <p>{{ $t( '_common:terms.room_plural' ) }}</p>
                        <div>
                            <NumericStep :min="1" :max="adultsCount" :value="roomsCount" :icons-attrs="iconsAttrs" @input="updateRoomsCount" />
                        </div>
                    </div>
                    <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                        <div class="d-flex align-start flex-row">
                            <p>
                                {{ $t( '_common:terms.children' ) }}
                            </p>
                            <span class="children-ages-label">({{ $t('labels.children') }})</span>
                        </div>
                        <div>
                            <NumericStep :min="0" :max="99" :value="childrenCount" :icons-attrs="iconsAttrs" @input="updateChildrenCount" />
                        </div>
                    </div>
                    <div v-if="childrenCount" class="children-ages-container d-flex flex-wrap">
                        <div v-for="(_, idx) in childrenCount" :key="idx" ref="childAgeContainer" class="col-4 px-0 py-1">
                            <div class="child-age">
                                <p>{{ $t( '_common:terms.childAge', { index: idx + 1 } ) }}</p>
                                <BaseFormSelect
                                    :id="`select.childAges${idx}`"
                                    v-model="childAges[idx]"
                                    v-ripple
                                    :options="childAgeOptions"
                                    :clearable="false"
                                    data-test-id="select.children"
                                    class="gst-base-form-select-variant-2 u-width-100 u-height-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="gst-search-hotel-modal__footer">
            <div class="gst-search-hotel-modal__buttons">
                <BaseButton
                    color="primary"
                    :disabled="!startDate"
                    class="text-capitalize"
                    data-test-id="searchHotelButtonApply"
                    width="90%"
                    @click="save">
                    {{ $t('_common:buttons.apply') }}
                </BaseButton>
                <BaseButton
                    text
                    color="primary"
                    :disabled="!startDate"
                    class="text-capitalize"
                    width="90%"
                    @click="reset( )">
                    {{ $t('_common:buttons.reset') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import NumericStep from '@core/shared/components/input/NumericStep';
    import LocationSearchStoreDropDown from '@tenant/app/components/misc/LocationSearchStoreDropDown/LocationSearchStoreDropDown';
    import HeaderVariant1 from '@tenant/app/components/modals/HeaderVariant1';
    import DatePickerTooltipDisabledDates from '@tenant/app/components/date/DatePickerTooltipDisabledDates.vue';
    import BaseFormSelect from '@tenants/ticketmaster/app/components/inputs/BaseFormSelect.vue';
    import FilterMixin from './AddHotelReservation/FilterMixin';

    export default {
        name: 'SearchHotelExpandedMobileModal',
        components: {
            DatePickerTooltipDisabledDates,
            BaseButton,
            HeaderVariant1,
            NumericStep,
            BaseFormSelect,
            LocationSearchStoreDropDown,
        },
        mixins: [
            FilterMixin
        ],
        data( ) {
            return {
                isFormWizard: true
            };
        },
        mounted( ) {
            this.$refs.location.focus( );
            this.unwatchLocationChange = this.$watch(
                'location',
                function( value ) {
                    if ( this.isLocation( value ) ) {
                        this.isFormWizard = false;
                        this.unwatchLocationChange( );
                    }
                },
                {
                    deep: true
                }
            );
        }
    };
</script>
